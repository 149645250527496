/* Ensure html and body take full height */
html, body {
    height: 100%;
    margin: 0;
    padding: 0;
}

body {
    font-family: 'Arial', sans-serif;
}

/* Light Theme */
.light-theme-body {
    /* background: linear-gradient(135deg, #FFFFFF, #E8EAF6, #C5CAE9, #9FA8DA); */
    background: linear-gradient(135deg, #9FA8DA, #C5CAE9, #C5CAE9, #9FA8DA);
    min-height: 100vh;
    display: flex;
    flex-direction: column;
}

.light-theme-navbar {
    background-color: #FFFFFF;
}

.light-theme-text {
    color: #3D52A0;
}

.light-theme-bg-success {
    background-color: #C5CAE9;
}

.light-theme-bg-danger {
    background-color: #9FA8DA;
}

.light-theme-bg-info {
    background-color: #E8EAF6;
}

.light-theme-bg-light-blue {
    background-color: #C5CAE9;
}

.light-theme-modal-content {
    background-color: #FFFFFF;
}

/* Dark Theme */
.dark-theme-body {
    /* background: linear-gradient(135deg, #2C3531, #116466, #D9B08C, #FFCB9A); */
    background: linear-gradient(105deg, #2C3531, #116466, #116466, #2C3531);
    min-height: 100vh;
    display: flex;
    flex-direction: column;
}

.dark-theme-navbar {
    background-color: #2C3531;
}

.dark-theme-text {
    color: #D1E8E2;
}

.dark-theme-bg-success {
    background-color: #116466;
}

.dark-theme-bg-danger {
    background-color: #2C3531;
}

.dark-theme-bg-info {
    background-color: #D9B08C;
}

.dark-theme-bg-light-blue {
    background-color: #FFCB9A;
}

.dark-theme-bg-clear {
    background-color: transparent;
    outline: none;
    border: none;
}


.dark-theme-modal-content {
    background-color: #2C3531;
}

/* Shared styles */
.status-circle {
    border-radius: 50%;
    width: 40px;
    height: 40px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-size: 1.2em;
    cursor: pointer;
    margin-right: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.status-circle:hover {
    transform: scale(1.1);
    box-shadow: 0 6px 8px rgba(0, 0, 0, 0.15);
}

.toggle-button {
    border-radius: 50%;
    width: 40px;
    height: 40px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-size: 1.2em;
    cursor: pointer;
    margin-right: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.toggle-button:hover {
    transform: scale(1.1);
    box-shadow: 0 6px 8px rgba(0, 0, 0, 0.15);
}

.light-theme-toggle-button {
    background-color: #C5CAE9;
}

.dark-theme-toggle-button {
    background-color: #FFCB9A;
}

.modal-content {
    border-radius: 20px;
    padding: 20px;
}

.modal-header {
    border-bottom: none;
    padding-bottom: 0;
    width: 100%;
    display: flex;
    justify-content: flex-end;
}

.modal-body {
    font-size: 1.1em;
    padding-top: 0;
    text-align: center;
    width: 100%;
}

.modal-footer {
    border-top: none;
    padding-top: 0;
    width: 100%;
    display: flex;
    justify-content: center;
}

/* Rounded corners for table */
.table-bordered {
    border-radius: 10px;
    overflow: hidden;
}

/* Light Theme Button Styles */
.light-theme-btn {
    background-color: #3D52A0;
    border-color: #3D52A0;
    color: white;
}

.light-theme-btn:hover {
    background-color: #2C3E75;
    border-color: #2C3E75;
}

/* Light Theme Delete Button */
.light-theme-delete-btn {
    background-color: #E74C3C; /* Red color for delete */
    border-color: #E74C3C;
    color: white;
}

.light-theme-delete-btn:hover {
    background-color: #C0392B; /* Darker red on hover */
    border-color: #C0392B;
}

/* Dark Theme Button Styles */
.dark-theme-btn {
    background-color: #D1E8E2;
    border-color: #D1E8E2;
    color: #2C3531;
}

.dark-theme-btn:hover {
    background-color: #B0C4C6;
    border-color: #B0C4C6;
}

/* Dark Theme Delete Button */
.dark-theme-delete-btn {
    background-color: #FF6F61; /* Lighter red for dark theme */
    border-color: #FF6F61;
    color: #2C3531; /* Dark text color */
}

.dark-theme-delete-btn:hover {
    background-color: #E55B52; /* Darker red on hover */
    border-color: #E55B52;
}

/* WebRTC status circles */
.webrtc-status-circle {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    margin-left: 0px;
    margin-right: 10px;
    font-size: 12px;
}

.connected {
    background-color: #2ECC71;
}

.disconnected {
    background-color: #E74C3C;
}

.ready {
    background-color: #2ECC71;
}

.not-ready {
    background-color: #E74C3C;
}

.peer-info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.indicators {
    display: flex;
    gap: 10px;
}
